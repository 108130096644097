import * as PhotoSwipe from './photoswipe'
import * as PhotoSwipeUI_Default from './photoswipe-ui-default'

export function initPhotoSwipeFromDOM(gallerySelector) {

  // parse slide data (url, title, size ...) from DOM elements
  // (children of gallerySelector)
  const parseThumbnailElements = function(el) {
    const thumbElements = el.getElementsByClassName('js-pswp-element');
    const numNodes = thumbElements.length;
    let items = [];

    for(let i = 0; i < numNodes; i++) {

      const figureEl = thumbElements[i];

      // include only element nodes
      if(figureEl.nodeType !== 1) continue;

      const linkEl = figureEl.getElementsByClassName('js-pswp-open')[0];

      const size = linkEl.getAttribute('data-size').split('x');

      // create slide object
      const item = {
        src: linkEl.getAttribute('href'),
        w: parseInt(size[0], 10),
        h: parseInt(size[1], 10)
      };

      if(figureEl.getElementsByClassName('js-pswp-caption')[0]) {
        item.title = figureEl.getElementsByClassName('js-pswp-caption')[0].innerHTML;
      }

      if(linkEl.getElementsByClassName('js-pswp-img')[0]) {
        item.msrc = linkEl.getElementsByClassName('js-pswp-img')[0].getAttribute('data-src');
      }

      item.el = figureEl;
      items.push(item);
    }

    return items;
  };

  // find nearest parent element
  const closest = function closest(el, fn) {
    return el && ( fn(el) ? el : closest(el.parentNode, fn) );
  };

  // triggers when user clicks on thumbnail
  const onThumbnailsClick = function(e) {
    e = e || window.event;
    e.preventDefault ? e.preventDefault() : e.returnValue = false;

    const eTarget = e.target || e.srcElement;

    // find root element of slide
    const clickedListItem = closest(eTarget, function(el) {
      return (el.classList.contains('js-pswp-element'));
    });

    if(!clickedListItem) {
      return;
    }

    // find index of clicked item by looping through all child nodes
    // alternatively, you may define index via data- attribute
    const clickedGallery = clickedListItem.closest(gallerySelector);
    const childNodes = clickedGallery.getElementsByClassName('js-pswp-element');
    const numChildNodes = childNodes.length;
    let nodeIndex = 0;
    let index = '';

    for (let i = 0; i < numChildNodes; i++) {
      if(childNodes[i].nodeType !== 1) {
        continue;
      }

      if(childNodes[i] === clickedListItem) {
        index = nodeIndex;
        break;
      }
      nodeIndex++;
    }

    if(index >= 0) {
      // open PhotoSwipe if valid index found
      openPhotoSwipe( index, clickedGallery );
    }
    return false;
    };

    // parse picture index and gallery index from URL (#&pid=1&gid=2)
    const photoswipeParseHash = function() {
      const hash = window.location.hash.substring(1);
      let params = {};

      if(hash.length < 5) {
        return params;
      }

      const consts = hash.split('&');
      for (let i = 0; i < consts.length; i++) {
        if(!consts[i]) {
            continue;
        }
        const pair = consts[i].split('=');
        if(pair.length < 2) {
            continue;
        }
        params[pair[0]] = pair[1];
      }

      if(params.gid) {
        params.gid = parseInt(params.gid, 10);
      }

      return params;
    };

    const openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
      const pswpElement = document.querySelectorAll('.pswp')[0];
      const items = parseThumbnailElements(galleryElement);
      // define options (if needed)
      const options = {

        allowPanToNext:true,
        spacing: 0.12,
        bgOpacity: 1,
        mouseUsed: false,
        loop: true,
        pinchToClose: true,
        closeOnScroll: true,
        closeOnVerticalDrag: true,
        verticalDragRange: 0.75,
        hideAnimationDuration: 0,
        showAnimationDuration: 0,
        showHideOpacity: false,
        focus: true,
        escKey: true,
        arrowKeys: true,
        mainScrollEndFriction: 0.35,
        panEndFriction: 0.35,

        // define gallery index (for URL)
        galleryUID: galleryElement.getAttribute('data-pswp-uid'),

        getThumbBoundsFn: function(index) {
          // See Options -> getThumbBoundsFn section of documentation for more info
          const thumbnail = items[index].el.getElementsByClassName('js-pswp-img')[0]; // find thumbnail
          const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
          const rect = thumbnail.getBoundingClientRect();

          return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
        }
      };

      // PhotoSwipe opened from URL
      if(fromURL) {
        if(options.galleryPIDs) {
          // parse real index when custom PIDs are used
          // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
          for(let j = 0; j < items.length; j++) {
            if(items[j].pid == index) {
              options.index = j;
              break;
            }
          }
        } else {
          // in URL indexes start from 1
          options.index = parseInt(index, 10) - 1;
        }
      } else {
        options.index = parseInt(index, 10);
      }

      // exit if index not found
      if(isNaN(options.index)) {
        return;
      }

      if(disableAnimation) {
        options.showAnimationDuration = 0;
      }

      // Pass data to PhotoSwipe and initialize it
      const pswp_gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
      pswp_gallery.init();
    };

    // loop through all gallery elements and bind events
    const galleryElements = document.querySelectorAll(gallerySelector);

    for(let i = 0, l = galleryElements.length; i < l; i++) {
      galleryElements[i].setAttribute('data-pswp-uid', i+1);
      const t = galleryElements[i].getElementsByClassName('js-pswp-open')
      for (let ii = 0; ii < t.length; ii++) {
        t[ii].onclick = onThumbnailsClick;
      }
    }

    // Parse URL and open gallery if it contains #&pid=3&gid=1
    const hashData = photoswipeParseHash();
    if(hashData.pid && hashData.gid) {
      openPhotoSwipe( hashData.pid ,  galleryElements[ hashData.gid - 1 ], true, true );
    }
};