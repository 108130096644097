require('intersection-observer');
import lozad from 'lozad';
const imagesLoaded = require('imagesloaded');

const closest = (el, targetClass) => {
  for (let item = el; item; item = item.parentElement) {
    if (item.classList.contains(targetClass)) {
      return item
    }
  }
}

export function lazyload() {
  const observer = lozad('.lozad', {
    rootMargin: '1000px 0px', // syntax similar to that of CSS Margin
    threshold: 0.1, // ratio of element convergence
    load: function(el) {
      const src = el.getAttribute('data-src');
      el.setAttribute('src', src);
      imagesLoaded(el, { background: true }, () => {
        el.classList.add('loaded');
        const parent = closest(el, 'js-lozad-parent');
        if(parent) {
          parent.classList.add('loaded');
        }
      });
    }
  });
  const observerBg = lozad('.lozad-bg', {
    load: function(el) {
      const src = el.getAttribute('data-src');
      el.style.backgroundImage = `url(${src})`; 
      imagesLoaded(el, { background: true }, () => {
        el.classList.add('loaded');
        const parent = closest(el, 'js-lozad-parent');
        if(parent) {
          parent.classList.add('loaded');
        }
      });
    }
  });
  observer.observe();
  observerBg.observe();
}