// Stylesheets
import './assets/scss/site.scss';

import {fixSvgIntrinsicSizing} from './assets/modules/fixSvgIntrinsicSizing';
import {hover} from './assets/modules/hover';
import {nav} from './assets/modules/nav';
import {langnav} from './assets/modules/langnav';
//import {modal} from './assets/modules/modal';
import {lazyload} from './assets/modules/lazyload';
import {yubinbango2} from './assets/modules/yubinbango2';
import {initPhotoSwipeFromDOM} from './assets/modules/photoswipe-init';
import {Pjax} from 'pjax-api';

const init = () => {
  fixSvgIntrinsicSizing();
  hover();
  langnav();
  lazyload();
  yubinbango2();
  initPhotoSwipeFromDOM('.js-pswp-container');
}

nav();
init();

new Pjax({
  areas: [
    '#main, #nav',
    'body'
  ],
  update: {
    css: false,
    script: false
  }
});
 
document.addEventListener('pjax:ready', function(e) {
  document.body.classList.remove('is-nav-active');
  new YubinBango.MicroformatDom();
  init();
  gtag('config', ga_code, {'page_path' : location.pathname});
});